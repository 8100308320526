import { resetFilters } from '@htmniseko/htm-components/Facet';
import { load } from 'state/modules/search/actions';
import { sendPropertyDetailsViewEvent, sendSearchResultsEvent } from 'utils/analytics';
import types from './types';

const { LOAD_SUCCESS, RESET_CONTACT_REQUEST, SET_DETAILS } = types;

export default () => ({ dispatch }) => next => action => {
  switch (action.type) {
    case RESET_CONTACT_REQUEST: {
      dispatch(resetFilters());
      dispatch(load({}));
      break;
    }
    case LOAD_SUCCESS: {
      // Submit data to Google Analytics
      sendSearchResultsEvent(action.result);
      break;
    }
    case SET_DETAILS: {
      // Submit data to Google Analytics
      sendPropertyDetailsViewEvent(action.id);
      break;
    }
    default: {
      break;
    }
  }
  next(action);
};
