import moment from 'moment';
import { cartCookieConfig, consentCookieConfig } from '_config/constants/cookies';
import { getCookieDomain } from 'utils/misc';

const SECURE = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test';

export function setCookie(name, value, expireAt) {
  const domain = getCookieDomain(window.location.hostname);
  // max-age is used in Safari, expires is used in Chrome and Firefox
  const maxAge = (expireAt.toDate() - new Date()) / 1000;
  document.cookie = `${name}=${value};expires=${expireAt};max-age=${maxAge};domain=${domain};secure=${SECURE};path=/;samesite=none`;
}

export function setAcceptCookie() {
  const expireAt = new Date();
  expireAt.setSeconds(expireAt.getSeconds() + consentCookieConfig.maxAge);
  setCookie(consentCookieConfig.name, consentCookieConfig.value, moment(expireAt));
}

export function setCartCookie(value, expireAt) {
  setCookie(cartCookieConfig.name, value, expireAt);
}
